import React from 'react'
import RedirectContainer from '@containers/RedirectContainer'

export default () => <RedirectContainer goToPath='/en/tiktokCreators' />

// NOT USED NOW, REDIRECT APPLIED
// /* eslint-disable */
// import React from 'react'
// import PropTypes from 'prop-types'
// import { Helmet } from 'react-helmet'
// import TikTokLayout from '@layouts/TikTokLayout'
// import TikTokFreebieContainer from '@containers/TikTokFreebiePage/TikTokFreebieContainer'
// import { freebieTiktokENFormScript } from '../../../externalScriptsCode/freebieTiktokPage'
// import avaliableLangs from '@localization/avaliableLangs'

// const IndexPage = ({ location }) => (
//   <>
//     <Helmet>
//       <meta charSet='utf-8' />
//       <title>The TikTok Bible for Influencers & Creators</title>
//       <link
//         rel='canonical'
//         href='https://reports.onlinepunks.com/en/freebie/tiktokCreators'
//       />
//       <meta
//         property='og:title'
//         content='The TikTok Bible for Influencers & Creators'
//       />
//       <meta
//         property='og:description'
//         content='TikTok e-book for Creators, presented by Onlinepunks'
//       />
//       <meta property='og:type' content='website' />
//       <meta name='twitter:card' content='summary' />
//       <meta
//         name='twitter:title'
//         content='The TikTok Bible for Influencers & Creators'
//       />
//       <meta
//         name='twitter:description'
//         content='TikTok e-book for Creators, presented by Onlinepunks'
//       />
//       <meta
//         property='og:image'
//         content='https://reports.onlinepunks.com/tiktokBible/Footer_feebie@1x.png'
//       />
//       <meta
//         name='twitter:image:src'
//         content='https://cdn.infludata.com/logo.png'
//       />
//       <meta name='twitter:domain' content='infludata.com' />
//       <script
//         id='pixel-script-poptin'
//         src='https://cdn.popt.in/pixel.js?id=df783521a0109'
//         async='true'
//       />
//       <script
//         async='true'
//         dangerouslySetInnerHTML={{ __html: freebieTiktokENFormScript }}
//         //the from script might change, now it is EN form, can depend in page lang in future
//         // will probably depend on lang of page
//       />
//     </Helmet>

//     <TikTokLayout
//       location={location}
//       showTaxesNote={false}
//       pageLanguage={avaliableLangs.en}
//     >
//       <TikTokFreebieContainer location={location} />
//     </TikTokLayout>
//   </>
// )

// IndexPage.propTypes = {
//   location: PropTypes.object.isRequired,
// }

// export default IndexPage
